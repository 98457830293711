$(document).bind('contextmenu', function (e) {

    $("#error").fadeIn("slow");
    setTimeout(function(){
        $("#error").fadeOut("slow");
    },2000);
    return false;

});

$('body').bind('cut copy paste', function (e) {
    $("#error").fadeIn("slow");
    setTimeout(function(){
        $("#error").fadeOut("slow");
    },2000);

    return false;
});

function applyMobileVhFix() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// We listen to the resize event
window.addEventListener('resize', () => {
    applyMobileVhFix();
});

$(document).on('ready', function() {
    applyMobileVhFix();
});
