(function (define) {
    define(['jquery'], function ($) {
        return (function () {
            var workbook = window.workbook || {};

            /* AUTHORIZATION **********************************************/
            //Implements Authorization API that simplifies usage of authorization scripts generated by workbook.

            workbook.avatar = workbook.avatar || {};

            //Deprecated. Use workbook.auth.isGranted instead.
            workbook.avatar.saveImage = function (imgData) {
                return false;
            };

            /* AUTHORIZATION **********************************************/
            //Implements Authorization API that simplifies usage of authorization scripts generated by workbook.

            workbook.presentation = workbook.presentation || {};

            workbook.presentation.saveResults = function (quizName, gradeScore, gradePercent, isFinal) {
                return;
            };

            workbook.presentation.reportResults= function (quizName, gradeScore, gradePercent, isFinal, showResults, resultsLayout) {
                return;
            };

            return workbook;
        })();
    });
}(typeof define === 'function' && define.amd
    ? define
    : function (deps, factory) {
        if (typeof module !== 'undefined' && module.exports) {
            module.exports = factory(require('jquery'));
        } else {
            window.workbook = factory(window.jQuery);
        }
    }));
