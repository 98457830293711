/**************************************************************************************
 * @name:       svgavatars.en.js - English file
 * @version:    1.5
 * @URL:        https://svgavatars.com
 * @copyright:  (c) 2014-2019 DeeThemes (https://codecanyon.net/user/DeeThemes)
 * @licenses:   https://codecanyon.net/licenses/regular
                https://codecanyon.net/licenses/extended
***************************************************************************************/
function svgAvatarsTranslation() {
	"use strict";

	// Ready for the translation to your language (any text inside quotes and please don't break JavaScript sintax!)
	var text = {
		welcomeSlogan: "<h2>Create an avatar to represent yourself in the course</h2>",
		welcomeMsg: "<p>please choose a gender</p>",
		waitMsg: "<p>please wait...</p>",
		randomMsg : "random",
		resetMsg : "reset",
		saveMsg : "save",
		shareMsg: "share",
		gravatarMsg: "Gravatar",
		downloadMsg : "download",
		svgFormatMsg: "svg - vector format",
		confirmMsg: "<h3>Are you sure?</h3><p>The all current changes will be lost.</p>",
		iosMsg: "<p>Please tap and hold the image and choose Save</p>",
		gravatarTitle: "<h3>You can upload to and use the created avatar on Gravatar.com</h3><p>Please enter your Gravatar email and password</p>",
		gravatarEmail: "Your Gravatar email",
		gravatarPwd: "Your Gravatar password",
		gravatarRating: "Rating:",
		gravatarNote: "<p><small>Note: Your email and password will NEVER be stored on our server</small></p>",
		installMsg: "upload",
		alertSvgSupportError: "Sorry, but your browser does not support SVG (Scalable Vector Graphic).<br> Avatar Generator cannot start.",
		alertJsonError: "<h3>Error loading graphic data!</h3><p>Please reload a page</p>",
		alertSuccess: "<h3>Your avatar is stored on our server.</h3><p>Thank you!</p>",
		alertError: "<h3>Avatar is not saved!</h3><p>Please try again</p>",
		alertErrorDownload: "<h3>An error occured!</h3><p>Please try again</p>",
		alertErrorImage: "<h3>The image is broken!</h3><p>Please try again</p>",
		alertErrorUploadsDir: "<h3>Error of uploading directory!</h3><p>Please check that /ready-avatars/ directory exists and is writable.</p>",
		alertErrorFileData: "<h3>Error file data!</h3><p>The avatar's data sent seems is not being PNG or SVG.</p>",
		alertErrorFileType: "<h3>Error file type!</h3><p>The avatar file is not PNG or SVG.</p>",
		alertSuccessGravatar: "<h3>Congratulations!</h3><p>You have successfully changed your Gravatar.</p><p>Please allow 5 to 10 minutes for avatar changes to take effect</p>",
		alertCommonErrorGravatar: "<h3>An unknown error occured!</h3><p>Please try again</p>",
		alertErrorRatingFail: "<h3>The wrong Rating answer!</h3><p>Don't change input radio values. Please try again</p>",
		alertErrorEmailFail: "<h3>Email is empty!</h3><p>Please enter your email and try again</p>",
		alertErrorPasswordFail: "<h3>Password is empty!</h3><p>Please enter your password and try again</p>",
		alertErrorImageFail: "<h3>An error with converting your avatar to PNG data!</h3><p>Please try again</p>",
		alertErrorFaultCode8: "<h3>Internal error on secure.gravatar.com</h3><p>Please try later</p>",
		alertErrorFaultCode9: "<h3>Incorrect Email or Password!</h3><p>Please check them and try again</p>",
		authoredMsg: "Graphic engine by ",
		okMsg: "ok",
		cancelMsg: "cancel",
		closeMsg: "close",
		tryAgainMsg: "try again",
		blockTitles: {
			face: "face",
			eyes: "eyes",
			hair: "hair",
			clothes: "clothes",
			backs: "backs"
		},
		bodyZoneTitles: {
			backs: "basic",
			faceshape: "shape",
			chinshadow: "", // not displayed, just for compatibility
			facehighlight: "", // not displayed, just for compatibility
			humanbody: "", // not displayed, just for compatibility
			clothes: "basic",
			hair: "on head",
			ears: "ears",
			eyebrows: "eyebrows",
			eyesback: "", // not displayed, just for compatibility
			eyesiris: "iris",
			eyesfront: "eye shape",
			mouth: "mouth",
			nose: "nose",
			glasses: "glasses",
			mustache: "mustache",
			beard: "beard"
		}
	};

	return text;
}
